.about-section {
    background-color: #f9f9f9;
    padding: 50px 20px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .about-section:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  }
  
  .animated-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #007bff;
    animation: fadeIn 1s ease-in-out;
  }
  
  .animated-subtitle {
    font-size: 2rem;
    font-weight: bold;
    color: #0056b3;
    animation: slideIn 1.2s ease-in-out;
  }
  
  .how-it-works {
    text-align: left;
    margin: 0 auto;
    max-width: 600px;
    padding: 15px;
    font-size: 1.1rem;
    list-style: none;
  }
  
  .how-it-works li {
    background-size: 20px;
    padding-left: 30px;
    margin-bottom: 10px;
  }
  
  .founders-container {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 30px;
    flex-wrap: wrap;
  }
  
  .founder-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    width: 250px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .founder-card:hover {
    transform: scale(1.05);
  }
  
  .founder-avatar {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .founder-name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .founder-role {
    font-size: 1.2rem;
    color: #555;
  }
  
  .founder-desc {
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .slogan {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007bff;
    margin-top: 20px;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes slideIn {
    from { opacity: 0; transform: translateX(-20px); }
    to { opacity: 1; transform: translateX(0); }
  }
  
  /* Mobile Responsive */
  @media (max-width: 768px) {
    .founders-container {
      flex-direction: column;
      align-items: center;
    }
  
    .about-section {
      padding: 30px 10px;
    }
  
    .animated-title {
      font-size: 2rem;
    }
  
    .animated-subtitle {
      font-size: 1.5rem;
    }
  }
  