body {
    background-color: #f0f4f8;
  }
  
  .root {
    min-height: 100vh;
    font-family: 'Noto Sans', 'Montserrat', sans-serif;
  }
  

  
  .logo {
    width: 150px;
  }
  
  /* Hero Section */
  .heroSection {
    text-align: center;
    padding: 100px 0;
    background-image: url("https://images.pexels.com/photos/112116/pexels-photo-112116.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
    background-size: cover;
    background-position: center;
    color: #fff;
    position: relative;
  }
  
  .heroSection::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Darkened to make text stand out */
    z-index: -1;
  }
  
  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px;
    background: linear-gradient(90deg, #007bff, #092877, #0e20ad, #0c5eb6); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: 200%;
    animation: gradientMove 5s infinite;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
  /* Search Bar */
  .searchBar {
    background-color: #fff;
    border-radius: 15px;
    padding: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .searchField {
    width: 23%;
    margin-right: 15px;
   
  }
  
  .animated-button {
    font-size: 1rem;
    padding: 15px;
    text-align: center;
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    transition: background-color 0.3s;
  }
  
  .animated-button:hover {
    background-color: #0056b3;
    color: #fff;
  }
  
  /* Info Section */
  .infoSection {
    padding: 60px 0;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .infoText {
    font-size: 1.2rem;
    color: #333;
  }
  
  /* MOBILE SCREEN SPECIFIC */
  @media (max-width: 768px) {

  
    .heroSection {
      padding: 60px 0;
    }
  
    .searchBar {
      flex-direction: column;
      gap: 15px;
      width: 90%; /* Reduced width for mobile */
      padding: 20px; /* Adjusted padding */
    }
  
    .searchField {
      width: 100%; /* Full width input fields on mobile */
      margin-right: 0;
    }
  
    .animated-button {
      width: 100%; /* Full width button on mobile */
      margin-top: 15px; /* Add some margin for better spacing */
    }
  
    h1 {
      font-size: 2.5rem;
    }
  
    .infoSection {
      padding: 30px 0;
    }
  }
  
  /* Small screen (mobile) */
  @media (max-width: 480px) {
    h1 {
      font-size: 2rem;
    }
  
    .searchBar {
      width: 95%; /* Further reduce width on very small screens */
      padding: 20px;
    }
}

  /* Spacing for the search bar container */
.searchBar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px; /* Increased gap for more spacing */
    padding: 25px; /* Increased padding for a larger container */
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    margin-top: 70px; /* Increased margin-top for more space from the header */
}

/* Style each input field */
.searchField {
    width: 22%; /* Slightly adjusted width */
    padding: 15px; /* Added more padding inside the input */
    border: 2px solid transparent;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    position: relative;
    background-color: white;
    z-index: 1;
}

.searchField:focus-within {
    transform: scale(1.05);
}

/* Additional margin between button and input fields */
.animated-button {
    font-size: 1rem;
    padding: 15px 20px; /* Added more padding for better visibility */
    margin-left: 10px; /* Added margin between the search button and fields */
    text-align: center;
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    transition: background-color 0.3s;
}

.animated-button:hover {
    background-color: #0056b3;
}

/* MOBILE SCREEN SPECIFIC */
@media (max-width: 768px) {
    /* Adjust padding and spacing for mobile */
    .searchBar {
        flex-direction: column; /* Stack the search fields vertically */
        gap: 15px;
        width: 80%; /* Slightly reduced width for mobile */
        padding: 20px; /* Adjusted padding */
    }

    .searchField {
        width: 100%; /* Full width input fields on mobile */
        margin-right: 0;
    }

    .animated-button {
        width: 100%; /* Full width button on mobile */
        margin-top: 15px; /* Add some margin for better spacing */
    }
}

/* Header */
.header {
    background-color: #eaf3fb62;
    color: #000;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    height: 8vh;
    position: relative;
    z-index: 2;
}

.logo {
    width: 150px;
}

/* Align buttons in a single row */
.navMenu {
    display: flex;
    gap: 20px;
    font-size: 1rem;
    align-items: center;
    color: #555;
}

.navButton {
    color: #000;
    background-color: transparent;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    transition: background-color 0.3s;
}

.navButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.signUpButton {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    transition: background-color 0.3s;
}

.signUpButton:hover {
    background-color: #0056b3;
}


/* Center the plane animation and control its movement */
.plane-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px; /* Reduced height for better spacing */
  overflow: hidden;
  position: relative;
}

/* Animate the plane to move across the screen */
.plane-animation img {
  width: 150px; 
  position: absolute;
  top: 50%; /* Align plane vertically */
  left: 70px; /* Start plane off-screen */
  animation: flyAcross 3s linear infinite; /* Smooth left-to-right movement */
}

@keyframes flyAcross {
  0% {
    left: 70px; /* Plane starts off-screen */
  }
  100% {
    left: calc(100% + 100px); /* Plane flies off the right of the screen */
  }
}
