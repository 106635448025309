.flight-card {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .flight-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }
  
  .flight-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
  }
  
  .flight-section {
    text-align: center;
    flex: 1;
  }
  
  .airport-code {
    font-size: 1.4rem;
    font-weight: bold;
    color: #1C3D93;
  }
  
  .airport-name {
    font-size: 0.9rem;
    color: #555;
  }
  
  .flight-time {
    font-size: 1rem;
    color: #007bff;
    margin-top: 5px;
  }
  
  .airline-logo-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
  }
  
  .airline-logo {
    width: 80px;
    height: auto;
  }
  
  .flight-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
  }
  
  .details-text {
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  .view-details {
    font-size: 1rem;
    color: #007bff;
    cursor: pointer;
    text-align: right;
  }
  
  .view-details:hover {
    text-decoration: underline;
  }
  
  /* Adding subtle animation */
  .flight-card:hover .airline-logo {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  